import styles from "./KrcsideMain.module.css";
import { Link, useNavigate } from 'react-router-dom';
import stylesm from "./KrcsideMainMobil.module.css";

const KrcsideMain = () => {
  const navigate = useNavigate();


  const digital = async (event) => {
    event.preventDefault();  
    navigate('https://www.linkedin.com/in/cankirac/');
  }

  const end = async (event) => {
    event.preventDefault();  
    navigate('/industries');
  }

  const invest = async (event) => {
    event.preventDefault();  
    navigate('/invest');
  }

  const isMobile = window.innerWidth < 768; // Ekran genişliği 768 pikselden küçükse mobil olarak kabul ediyoruz

  if (!isMobile){

  return (
    <div className={styles.krcsideMain}>
      <div className={styles.vectorParent}>
        <img className={styles.frameChild} alt="" src="/vector-11.svg" />
        <img className={styles.sadaa11} alt="" src="/sadaa-1-17@2x.png"  />
        <img className={styles.frameItem} alt="" src="/vector-21.svg" />
        <img
          className={styles.pngtreeDragonVectorIconIll}
          alt=""
          src="/pngtreedragonvectoriconill-1-13@2x.png"
          
         />
        <img className={styles.frameInner} alt="" src="/vector-31.svg" />
        <img
          className={styles.beyaz6gen111}
          alt=""
          src="/beyaz6gen-1-1-13@2x.png"
         

        />
        <div className={styles.cankirac}>cankirac</div>
        <div className={styles.ekranResmi2022051101231Parent}>
        <a href="https://www.linkedin.com/in/cankirac/" target="_blank">

          <img
            className={styles.ekranResmi2022051101231Icon}
            alt=""
            src="/ekran-resmi-20220511-0123-15@2x.png"
           
          />
          </a>
          <div className={styles.canKiracParent}>
            <div className={styles.canKirac}>Can Kirac</div>
            <div className={styles.krckrccorpnet}>krc@krccorp.net</div>
            <div className={styles.krckrccorpnet}>+905422816459</div>
          </div>
        </div>
      </div>
    </div>
  );

  }
  else {

  
  return (
    <div className={stylesm.krcsideMainMobil}>
      <div className={stylesm.vectorParent}>
        <img className={stylesm.frameChild} alt="" src="/vector-1.svg" />
        <img className={stylesm.sadaa11} alt="" src="/sadaa-1-16@2x.png" />
        <img className={stylesm.frameItem} alt="" src="/vector-2.svg" />
        <img
          className={stylesm.pngtreeDragonVectorIconIll}
          alt=""
          src="/pngtreedragonvectoriconill-1-12@2x.png"
        />
        <img className={stylesm.frameInner} alt="" src="/vector-3.svg" />
        <img
          className={stylesm.beyaz6gen111}
          alt=""
          src="/beyaz6gen-1-1-12@2x.png"
        />
        <div className={stylesm.ekranResmi2022051101231Parent}>
        <a href="https://www.linkedin.com/in/cankirac/" target="_blank">

          <img
            className={stylesm.ekranResmi2022051101231Icon}
            alt=""
            src="/ekran-resmi-20220511-0123-14@2x.png"
          />
          </a>
          <div className={stylesm.canKiracParent}>
            <div className={stylesm.canKirac}>Can Kirac</div>
            <div className={stylesm.krckrccorpnet}>krc@krccorp.net</div>
            <div className={stylesm.krckrccorpnet}>+905422816459</div>
          </div>
        </div>
      </div>
    </div>
  );
}







};

export default KrcsideMain;
