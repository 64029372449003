import { FunctionComponent } from "react";
import styles from "./KrcsideInvest.module.css";

const KrcsideInvest = () => {
  return (
    <div className={styles.krcsideInvest}>
      <div className={styles.ekranResmi2022051101231Parent}>
        <img
          className={styles.ekranResmi2022051101231Icon}
          alt=""
          src="/ekran-resmi-20220511-0123-1@2x.png"
        />
        <div className={styles.ger}>GERİ</div>
      </div>
      <div className={styles.beyaz6gen111Parent}>
        <img
          className={styles.beyaz6gen111}
          alt=""
          src="/beyaz6gen-1-1-1@2x.png"
        />
        <div className={styles.blockchainWrapper}>
          <div className={styles.blockchain}>Blockchain</div>
        </div>
        <div className={styles.bistWrapper}>
          <div className={styles.blockchain}>BIST</div>
        </div>
        <div className={styles.nftWrapper}>
          <div className={styles.blockchain}>NFT</div>
        </div>
      </div>
    </div>
  );
};

export default KrcsideInvest;
