import React, { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import { getDatabase, ref, set, onValue } from 'firebase/database';
import { initializeApp } from 'firebase/app';
import Ana from './pages/KrcsideMain.js';
import Digital from './pages/KrcsideDigital.js';
import End from './pages/KrcsideEndus.js';
import Indes from './pages/KrcsideInvest.js';
import GifViewer from './pages/GifViewer.js';
import GifViewerPaper from './pages/GifViewerPaper.js';

import { getFirestore, doc, setDoc } from 'firebase/firestore'; // Firestore import edildi

import './App.css';

const firebaseConfig = {
  apiKey: "AIzaSyBPa2RvufioWFIN1TajQ1ySd1pUiZKZbbE",
  authDomain: "hisseanaliz-803ed.firebaseapp.com",
  projectId: "hisseanaliz-803ed",
  storageBucket: "hisseanaliz-803ed.appspot.com",
  messagingSenderId: "485376323277",
  appId: "1:485376323277:web:f0f4c904864a26e80a4384",
  measurementId: "G-8YCWPLWTF7"
};
const app = initializeApp(firebaseConfig);
const db = getFirestore(app); // Firestore veritabanı referansı

function App() {

 
  return (
    <Routes>
      
      <Route path="" element={<Ana />} />
      <Route path="/digital" element={<Digital />} />
      <Route path="/industries" element={<End />} />
      <Route path="/invest" element={<Indes />} />
      <Route path="/authors.gif" element={<GifViewer />} /> {/* Bu satırı ekledik */}
      <Route path="/papers.gif" element={<GifViewerPaper />} /> {/* Bu satırı ekledik */}


    </Routes>
  );
}

export default App;
