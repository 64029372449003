import React, { useEffect } from 'react';
import { getFirestore, doc, setDoc } from 'firebase/firestore'; // Firestore import edildi

import { initializeApp } from 'firebase/app';

const firebaseConfig = {
    apiKey: "AIzaSyBPa2RvufioWFIN1TajQ1ySd1pUiZKZbbE",
    authDomain: "hisseanaliz-803ed.firebaseapp.com",
    projectId: "hisseanaliz-803ed",
    storageBucket: "hisseanaliz-803ed.appspot.com",
    messagingSenderId: "485376323277",
    appId: "1:485376323277:web:f0f4c904864a26e80a4384",
    measurementId: "G-8YCWPLWTF7"
  };
  const app = initializeApp(firebaseConfig);
  const db = getFirestore(app); // Firestore veritabanı referansı
const GifViewer = () => {
  useEffect(() => {
    const handleGifView = () => {
      const viewData = {
        timestamp: Date.now(),
        deviceInfo: navigator.userAgent, // Cihaz bilgisi
      };

      navigator.geolocation.getCurrentPosition((position) => {
        const { latitude, longitude } = position.coords;
        viewData.location = {
          lat: latitude,
          lng: longitude,
        };
        // Konum bilgisi alındığında Firestore'a kaydetme
        saveToFirestore(viewData);
      }, (error) => {
        console.error("Geolocation hatası:", error); // Geolocation hatası
        // Konum bilgisi alınamadığında da Firestore'a kaydetme
        saveToFirestore(viewData);
      });
    };
    const saveToFirestore = async (data) => {
        const today = new Date();
        const dateString = today.toISOString().split('T')[0]; // Gün tarihini YYYY-MM-DD formatında al
        const hourString = today.toISOString().split('T')[1].split('.')[0]; // Saat bilgisini al
  
        try {
          const docRef = doc(db, 'girenlerkrc', dateString); // Günlük belge referansı oluşturuldu
          // Saat bilgisi ile alt belge referansı oluşturulmadan, veriyi günlük belgeye ekle
          await setDoc(docRef, {
            [hourString]: data // Saat bilgisi anahtar olarak kullanıldı
          }, { merge: true }); // Mevcut belge ile birleştir
          console.log("Veri başarıyla kaydedildi."); // Başarılı kaydetme mesajı
        } catch (error) {
          console.error("Veri kaydetme hatası:", error); // Hata mesajı
        }
      };
    handleGifView(); // Bileşen yüklendiğinde kayıt işlemi yapılacak

  }, []);

  return (
    <div>
      {/* GIF görüntüleme işlemi burada yapılabilir */}
      <img src="https://krccorp.net/authorsgif.gif" alt="GIF" />
    </div>
  );
};

export default GifViewer;