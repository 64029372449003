

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux'; // react-redux paketinden Provider'ı dahil edin
import { BrowserRouter as Router } from 'react-router-dom';

import App from './App';
import store from './store'; // Redux store'unuzu import edin
import './index.css';

ReactDOM.render(
  <Provider store={store}> {/* Provider bileşenini kullanarak Redux store'unuzu sağlayın */}
    <Router>
      <App />
    </Router>
  </Provider>,
  document.getElementById('root')
);
