import { FunctionComponent } from "react";
import styles from "./KrcsideEndus.module.css";

const KrcsideEndus = () => {
  return (
    <div className={styles.krcsideEndus}>
      <div className={styles.ekranResmi2022051101231Parent}>
        <img
          className={styles.ekranResmi2022051101231Icon}
          alt=""
          src="/ekran-resmi-20220511-0123-1@2x.png"
        />
        <div className={styles.ger}>GERİ</div>
      </div>
      <div className={styles.pngtreeDragonVectorIconIllParent}>
        <img
          className={styles.pngtreeDragonVectorIconIll}
          alt=""
          src="/pngtreedragonvectoriconill-1-1@2x.png"
        />
        <div className={styles.krcEGunWrapper}>
          <div className={styles.krcEGun}>KRC E-GUN</div>
        </div>
        <div className={styles.krcBaphometWrapper}>
          <div className={styles.krcBaphomet}>KRC Baphomet</div>
        </div>
        <div className={styles.murphyControllerWrapper}>
          <div className={styles.krcEGun}>Murphy Controller</div>
        </div>
      </div>
    </div>
  );
};

export default KrcsideEndus;
