import { FunctionComponent } from "react";
import styles from "./KrcsideDigital.module.css";

import { Link, useNavigate } from 'react-router-dom';


const KrcsideDigital = () => {
  const navigate = useNavigate();


  const api = async (event) => {
    event.preventDefault();  
    navigate('/digital/api');
  }
  return (
    <div className={styles.krcsideDigital}>
      <div className={styles.ekranResmi2022051101231Parent}>
        <img
          className={styles.ekranResmi2022051101231Icon}
          alt=""
          src="/ekran-resmi-20220511-0123-1@2x.png"
        />
        <div className={styles.ger}>GERİ</div>
      </div>
      <div className={styles.frameParent}>
        <div className={styles.dgifmaker746321Parent}>
          <img
            className={styles.dgifmaker746321Icon}
            alt=""
            src="/3dgifmaker74632-1@2x.png"
          />
          <img className={styles.sadaa12} alt="" src="/sadaa-1-2@2x.png" />
          <div className={styles.mobilUygulamaWrapper}>
            <div className={styles.mobilUygulama}>Mobil Uygulama</div>
          </div>
        </div>
        <div className={styles.sadaa12Parent}>
          <img className={styles.sadaa121} alt="" src="/sadaa-1-21@2x.png" />
          <div className={styles.servislerWrapper}>
            <div className={styles.mobilUygulama}>Servisler</div>
          </div>
        </div>
        <div className={styles.sadaa12Group} onClick={api}>
          <img className={styles.sadaa122} alt="" src="/sadaa-1-22@2x.png" />
          <div className={styles.aplerWrapper}>
            <div className={styles.mobilUygulama}>APİ’ler</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default KrcsideDigital;
